import * as actionTypes from '../actions/actionsTypes';

const initialState = {
    shops :null,
    error : false,
    errorMessage : null
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.FETCH_SHOPS:
            return {
                ...state,
                shops : action.list,
                error : false
            }
        case actionTypes.ERROR_HUNDLING:
        return {
            ...state,
            error : true,
            errorMessage : action.message
        }
        default :
            return state;
    }
}

export default reducer;