import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import * as actions from '../../Store/actions';
import Loader from '../../Components/UI/Loader/Gears';
class Authsignup extends Component {

    
state = {
   
    email : '',
    password : '',
    terms : false,
    newsletter  : false,
    isSignup :true

}

submitHandler =(event) =>{
    event.preventDefault();

    const data = {
      email : this.state.email,
      password :  this.state.password,
      terms : this.state.terms,
      newsletter : this.state.newsletter
    };
    this.props.onAuth(data, this.state.isSignup );
  }

switchAuthModeHundler = () => {
  this.setState(prevState => {
    return { isSignup : !prevState.isSignup};
  })
}

passwordForgotHandler = () => {
  this.props.mHide();
  this.props.history.push('/forgot');
} 


handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({
          [name]: value
        });
      }
    render () {
     
      // console.log(this.props);
       
      let form = null;
      let errorMessage = null;
      if (this.props.error) {
        errorMessage = (<div className="alert alert-warning" role="alert" >{this.props.error.message}</div>);
        setTimeout(() => {errorMessage = (<div></div>)}, 6000);

      }

      if (this.state.isSignup) {
        form =(
          <div className="auth">
          {errorMessage}
          <img src="../img/logo.svg" alt="FindFix" width="150px" />
            <form  onSubmit={this.submitHandler}>
              <div className="form-group">
                    <input type="email" className="form-control" name="email"  value={this.state.email}
                onChange={this.handleInputChange} placeholder="Email" required />
                  </div>
                  <div className="form-group">
                    <input type="password" className="form-control" name="password" placeholder="Κωδικός"  value={this.state.password}
                onChange={this.handleInputChange} required />
                  </div>
                
                <div className="form-group">
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="gridCheck1" name="terms" checked={this.state.terms}
                onChange={this.handleInputChange} required/>
                    <label className="form-check-label" htmlFor="gridCheck1">
                      Με την εγγραφή αποδέχομαι τους <Link to="/terms">όρους χρήσης της υπηρεσίας</Link>
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="gridCheck2" name="newsletter"       checked={this.state.newsletter}
                onChange={this.handleInputChange} />
                    <label className="form-check-label" htmlFor="gridCheck2">
                        Επιθυμώ να λαμβάνω νέα και προσφορές.
                    </label>
                  </div>
                </div>
                <button type="submit" className="btn btn-findfix btn-block">Εγγραφή</button>
              </form>
              <button type="button" className="btn btn-link auth-btn-link"
                    onClick={this.switchAuthModeHundler}>
                    {this.state.isSignup ? 'Έχεις κάνει εγγραφή; Συνδέσου.' : 'Νέος χρήστης; Κάνε εγγραφή'}
              </button>
            </div>
        );
     
      }

         if  (!this.state.isSignup) {
        form = (
          <div className="auth">
          {errorMessage}
          <img src="../img/logo.svg" alt="FindFix" width="150px" />
            <form  onSubmit={this.submitHandler}>
                
                  <div className="form-group">
                    <input type="email" className="form-control" name="email"  value={this.state.email}
                onChange={this.handleInputChange} placeholder="Email" required />
                  </div>
                  <div className="form-group">
                    <input type="password" className="form-control" name="password" placeholder="Κωδικός"  value={this.state.password}
                onChange={this.handleInputChange} required />
                  </div>
                
                <button type="submit" className="btn btn-findfix btn-block">Σύνδεση</button>
              </form>
              <button type="button" className="btn btn-link auth-btn-link"  onClick={this.passwordForgotHandler}>Έχω ξεχάσει τον κωδικό μου</button>
              <br />
              <button type="button" className="btn btn-link auth-btn-link"
                    onClick={this.switchAuthModeHundler}>
                    {this.state.isSignup ? 'Έχεις κάνει εγγραφή; Συνδέσου.' : 'Νέος χρήστης; Κάνε εγγραφή'}
              </button>
            </div>
          );
         }

         if (this.props.loading) {
           form = <Loader />
         }

         if (this.props.message) {
          form =<p style={{textAlign : "center", fontWeight : 'bold', padding : '40px'}}>>{this.props.message}</p>
        }

        return (
                <div>
                  {form}
                </div>);

    };
}
const mapStateToProps = state => {
  return {
    loading : state.authReducer.loading,
    error : state.authReducer.error,
    message : state.authReducer.message
  }
}
const mapDispatchToProps = dispatch => {
  return {
    onAuth:(data, isSignup) => dispatch(actions.auth(data, isSignup)),
    mHide:() => dispatch(actions.modalBackdrop())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Authsignup));