import React, { Component } from 'react';
import { Switch , Route } from 'react-router-dom';
import asyncComponent from '../../hoc/asyncComponent';

import Main from '../Main/Main';
import Formal from '../Forms/Formal/Formal';
import Login from '../Forms/Login/Login';
import Shop from '../Forms/Shop/Shop';
import Confirmation from '../../Components/Confirmation/Confirmation';
import ConfirmEmail from '../ConfirmEmail/ConfirmEmail';
import Email from '../Forms/Email/Email';
// import Terms from '../../Components/Terms/Terms';
import Logout from '../Auth/Logout/Logout';
import Dashboard from '../Dashboard/Dashboard';
import ConfirmMessage from '../../Components/ConfirmMessage/ConfirmMessage';

const AsyncForgot = asyncComponent(() => {
  return import('../Forms/Forgot/Forgot');
});
const AsyncReset = asyncComponent(() => { 
  return import('../Forms/Reset/Reset');
});

const AsyncTerms = asyncComponent(() => { 
  return import('../../Components/Pages/Terms/Terms');
});

const AsyncContact = asyncComponent(() => { 
  return import('../../Components/Pages/Contact/Contact');
});

const AsyncOperation = asyncComponent(() => { 
  return import('../../Components/Marketing/Operation');
});

const AsyncPricing = asyncComponent(() => { 
  return import('../../Components/Marketing/Pricing');
});



class Layout extends Component {
    render () {
        return (
            <Switch>
                <Route path="/email-confirmation" component ={ConfirmEmail} />
                <Route path="/email/"  component ={ Email } />
                <Route path="/terms" component ={AsyncTerms} />
                <Route path="/contact" component ={AsyncContact} />
                <Route path="/operation" component ={AsyncOperation} />
                <Route path="/pricing" component ={AsyncPricing} />
                <Route path="/shop" component = {Shop} />
                <Route path="/logout" component ={Logout} />  
                <Route path="/formal" component = {Formal} /> 
                <Route path="/confirmation" component = {Confirmation} />  
                <Route path="/confirmMessage/:issueId" component = {ConfirmMessage} />  
                <Route path="/login"  component ={ Login } />
                <Route path="/forgot" component = { AsyncForgot } />
                <Route path="/reset/:token"  component ={ AsyncReset } />
                <Route path="/Dashboard" component = { Dashboard } />
                <Route path="/" exact component ={Main} />
                <Route component= {Main} />
            </Switch>
        )

    }
}

export default Layout