import React from 'react';
import './Spinner.css';

const spinner = ( props ) => { 
    return ( <div className="gears">
    <img src="../img/big-gear.png" alt="gear" className="big" />

    <img src="../img/small-gear.png" alt="gear" className="small" />
</div>);
}

export default spinner;