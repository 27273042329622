import React, { Component } from 'react';
import axios from '../../axios';


class Email extends Component {
    state = {
        email : sessionStorage.getItem('partnerEmail'),
        loader : false,
    }

    
    handleClick = ( event ) => {
        event.preventDefault();
        const resendEmail = {
            email : sessionStorage.getItem('partnerEmail') };
            // email : 'theoharis@comexperts.gr' };
        axios.post( '/partner/resendEmail', resendEmail )
            .then()
            .catch();
        
    
    }


    render () {
    return (
        <div className="container" style={{minHeight : '70vh', textAlign: "center"}}>
            <h3 style={{marginTop : "100px"}}>Ας αρχίσουμε</h3>
            <p>Ελέγξτε τα εισερχόμενά σας για να επαληθεύσετε το email σας<br />Έχουμε στείλει ένα μήνυμα ηλεκτρονικού ταχυδρομείου στο <strong>{this.state.email}.</strong><br />Ακολουθήστε τις οδηγίες για να επιβεβαιώσετε τη διεύθυνση ηλεκτρονικού ταχυδρομείου σας.</p>
            <img src="../img/email.png" alt="email" width="100"/> 
            <p style={{cursor:"pointer"}} onClick={this.handleClick}>Δεν λάβατε το μήνυμα επαλήθευσης; <strong>Αποστολή ξανά...</strong></p>
            <p>Η επαλήθευση μέσω ηλεκτρονικού ταχυδρομείου μας βοηθά να διασφαλίσουμε ότι τα δεδομένα σας θα είναι πάντα ασφαλή</p>
        </div>
        );
    } 
}

export default Email;