import React from 'react'


const how = () => {
 return(<div className="container">
    <div className="how"> 
    <h5>Μια εφαρμογή με πολλά πλεονεκτήματα</h5>
        <div className="divider"></div>
        <div className="row">
            <div className="col-lg-4 col-md-4 col-s-12 col-xs-12">
                <img src="../img/001.png" alt="users" height="100px" />
                <h4>Απόκτησε νέους πελάτες</h4>
                <p>Προβάλλοντας  της επιχείρησης σου στο <strong>FindFix</strong></p>
            </div> 
            <div className="col-lg-4 col-md-4 col-s-12 col-xs-12">
                <img src="../img/002.png" alt="growth" height="100px" />
                <h4>Μεγάλωσε την επιχείρηση σου</h4>
                <p>Εκατοντάδες πελάτες ψάχνουν καθημερνά τεχνικό για την συσκευή τους μέσα από το <strong>FindFix</strong></p>
                
            </div> 
            <div className="col-lg-4 col-md-4 col-s-12 col-xs-12">
                <img src="../img/003.png"  alt="money" height="100px" />
                <h4>Αύξησε τα έσοδα σου</h4>
                <p>Κατά μέσο όρο, οι επιχειρήσεις βλέπουν την αύξηση του service κατά 2 φορές μετά τη συνεργασία με το <strong>FindFix</strong></p>
                
            </div>  
        </div>
    </div>
</div>)
}

export default how;