import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Modal from '../../Components/UI/Modal/Modal';
import Nav from '../../Components/Nav/Nav';

import AuthSignup from '../Auth/AuthSignup';
import AuthLogin from '../Auth/AuthLogin';

import './Header.css';

import * as actions from '../../Store/actions/index';



class Header extends Component {

    state = {
    }
   
    onModalShow =(value) => {
    this.props.show ? this.props.mHide() : this.props.mShow(value)
    }




    render() {
     let element = null;
        if(this.props.partner) {element = <Redirect to="/dashboard" />;}
        let modalHtml = null;
        switch (this.props.mdlInputText) {
            case 'ΣΥΝΔΕΣΗ':
            modalHtml =<AuthLogin />
                break;
            case 'ΕΓΓΡΑΦΗ':
            modalHtml =<AuthSignup />
                break;
            default:
                break;
        }
      return (
        <div className="header">
            {element}
            <Modal show={this.props.show} backdropClose={this.onModalShow}>
                {modalHtml}
            </Modal>
            <Nav  modalShow={this.onModalShow} pathname = {this.props.location.pathname}/>
        </div>
        
        )
    }
  }

    const mapStateToProps = state => {

      return {
        partner : state.authReducer.partnerId,
        show : state.uiReducer.show,
        mdlInputText : state.uiReducer.modalInput,

      };
  };
  
  const mapDispatchToProps = dispatch => {
  
      return {
        mShow:(value) => dispatch(actions.modalShow(value)),
        mHide:() => dispatch(actions.modalBackdrop())

        
      };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(Header);