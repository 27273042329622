import React  ,{ Component } from 'react';
import { Cookies } from 'react-cookie';
import axios from '../../axios';
import Gears from '../../Components/UI/Loader/Gears';

//δημιουργία cookie
const cookies = new Cookies();


class Shop extends Component {

  state = {
    partner : cookies.get('partnerId'),
    shop : [],
    newShop  : {
      route : '',
      street_number : '',
      locality : '',
      postal_code : '',
      phone_number : '',
    },
    showForm : false,
    loading : true
  }

componentDidMount() {
  // axios.get(`/shop/active/${this.state.partner}`)
  axios.get(`/shop/partner/${this.state.partner}`)
  .then(result => {
  this.setState({shop : result.data, loading : false})
  })
  .catch(err =>{
    console.log(err);
  })

}

showFormHundler = () => {
  this.setState(prevState => ({
    showForm: !prevState.showForm
  }));
}

handleChange =(event) => {
  const target = event.target;
  const value =target.value;
  const name = target.name;

  this.setState(
    prevState => ({
      newShop: {
          ...prevState.newShop,
          [name]: value
      }
      })
  );
}

handleSubmit = (event)=> {
  const details = {
    shop_name : this.state.shop[0].shop_name,
    route : this.state.newShop.route,
    street_number : this.state.newShop.street_number,
    locality : this.state.newShop.locality,
    postal_code : this.state.newShop.postal_code,
    phone_number : this.state.newShop.phone_number,
    logo : this.state.shop[0].logo,
    website : this.state.shop[0].website,
    partner : cookies.get('partnerId'),
    description: this.state.shop[0].description,
    first_name: this.state.shop[0].first_name,
    last_name: this.state.shop[0].last_name,

  }

  // alert(JSON.stringify(details));
  axios.post( '/shop/newshop', details )
      .then( response => { 
        alert('Καταχωρήσατε το νεο κατάστημα')
        axios.get(`/shop/partner/${this.state.partner}`)
        .then(result => {
        this.setState({shop : result.data, showForm : false})
        })
        .catch(err =>{
          console.log(err);
        })
      
      }).catch((err) => {
        alert(err.response.data.message)
          // this.setState( { loa false, ding:error : true,
          //     errorMessage : err.response.data.message});
      });
  event.preventDefault();
}


render () {
  console.log(this.state.shop)
  let element = <Gears />
 
let addShop = <button onClick={this.showFormHundler} className="btn btn-primary">Προσθήκη υποκαταστήματος</button>;
const { route , street_number, locality, postal_code , phone_number} = this.state.newShop
if (this.state.showForm) { addShop =(
  <form onSubmit={this.handleSubmit}>
    <h5 style={{marginTop : '40px'}}>Προσθήκη νέου υποκαταστήματος</h5>
    <div className="form-group row">
      <div className="col-8">
        <input type="text" className="form-control" name="route" value={route} onChange={this.handleChange} placeholder="Οδός" />
      </div>
      <div className="col-4">
        <input type="text" className="form-control" name="street_number" value={street_number} onChange={this.handleChange} placeholder="Αριθμός" />
      </div>
    </div>
    <div className="form-group row">
      <div className="col-8">
        <input type="text" className="form-control" name="locality" value={locality} onChange={this.handleChange} placeholder="Περιοχή" />
      </div>
      <div className="col-4">
        <input type="text" className="form-control" name="postal_code" value={postal_code} onChange={this.handleChange} placeholder="Τ.Κ." />
      </div>
    </div>
    <div className="form-group">
      <input type="text" className="form-control" name="phone_number" value={phone_number} onChange={this.handleChange} placeholder="Τηλέφωνο" />
    </div>
    <button type="submit" className="btn btn-primary">Αποθήκευση</button>
  </form>
)
}

let shop_name, value = null;
  if (this.state.shop.length > 0) {
    shop_name = this.state.shop[0].shop_name;
    value =   Object.values(this.state.shop).map((data,index) => {
      return (<h5 key={index}>
                Υποκατάστημα {index + 1} : {data.route} {data.street_number} {data.locality} {data.postal_code} <strong>Τηλέφωνο : {data.phone_number}</strong>
      </h5>)
   })
  }


  if(!this.state.loading) {
    element = (  <div className="container">
                    <h1>{shop_name}</h1>
                    {value}
                    { addShop }
                  </div>);
    }
  return(
      <div style={{minHeight : '80vh'}}>
      { element }
      </div> 
    );
  }
}

  export default Shop