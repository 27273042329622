import React, { Component } from 'react';
import Input from '../../../Components/UI/input/input'
import Button from '../../../Components/UI/Button/Button';
import Spinner from '../../../Components/UI/Spinner/Spinner';
import axios from '../../../axios';


class Shop extends Component {


state = {
    address : {
        shop_name : {elementType : 'input',
        elementConfig : {
            type : 'text',
            placeholder : 'Διακριτικός τίτλος',
            required : true
        },
        value : ''
    },
        first_name: {elementType : 'input',
        elementConfig : {
            type : 'text',
            placeholder : 'Όνομα υπευθύνου'
        },
        value : ''
    },
        last_name: {elementType : 'input',
        elementConfig : {
            type : 'text',
            placeholder : 'Επώνυμο υπευθύνου'
        },
        value : ''
        },
        
    route: {elementType : 'input',
        elementConfig : {
            type : 'text',
            placeholder : 'Όδός'
        },
        value : ''
    },
    street_number: {elementType : 'input',
        elementConfig : {
            type : 'text',
            placeholder : 'Αριθμός'
        },
        value : ''
    },
    locality: {elementType : 'input',
        elementConfig : {
            type : 'text',
            placeholder : 'Πόλη'
        },
        value : ''
    },
    postal_code: {elementType : 'input',
        elementConfig : {
            type : 'text',
            placeholder : 'Τ.Κ.'
        },
        value : ''
    },
    
    phone_number: {elementType : 'input',
        elementConfig : {
            type : 'text',
            placeholder : 'Τηλέφωνο καταστήματος',
            required: true,
        },
        value : ''

    },
   website: {elementType : 'input',
    elementConfig : {
        type : 'text',
        placeholder : 'website',
    },
    value : ''

    },
    },
    loading: false,
    error : false,
    errorMessage : null,
}




orderHandler = ( event ) => {
    event.preventDefault();
    this.setState( { loading: true } );
    const formData = {};
    for (let formElementIdentifier in this.state.address) {
        formData[formElementIdentifier] = this.state.address[formElementIdentifier].value;
    }
  

    const description = {
        payment : ['Μετρητά', 'Πιστωτική Κάρτα'],
        services : ["Επίσκεψη στο κατάστημα"],
        category :  [ 
            "Laptop", 
            "Κινητό", 
            "Tablet"
        ],
        working_hours :'09:00 - 21:00',
        partner: sessionStorage.getItem('partnerId'),
    }

    axios.post('/partner/description/', description)
                .then((response) => {
                    // console.log('Description saved', response);
                    //αφου σωσωτο description δημιουργώ το shop 
                    const details = {
                        first_name :formData.first_name,
                        last_name : formData.last_name,
                        shop_name : formData.shop_name,
                        route : formData.route,
                        street_number : formData.street_number,
                        locality : formData.locality,
                        postal_code : formData.postal_code,
                        website : formData.website,
                        phone_number : formData.phone_number,
                        partner: sessionStorage.getItem('partnerId'),
                        description : response.data.result._id
                    }

                    axios.post( '/shop', details )
                        .then( response => { 
                            this.setState( { loading: false } );
                            this.props.history.push('/formal')
                        }).catch((err) => {
                            this.setState( { loading: false, error : true,
                                errorMessage : err.response.data.message});
                        });
                
                } )
                .catch( error => {
                    this.setState( { loading: false, error : true,
                        errorMessage : error.response.data.message});
                } );
}


inputChangedHandler = (event, inputIdentifier) => {
    const updatedOrderForm = {
        ...this.state.address
    };
    const updatedFormElement = { 
        ...updatedOrderForm[inputIdentifier]
    };
    updatedFormElement.value = event.target.value;
    updatedOrderForm[inputIdentifier] = updatedFormElement;
    this.setState({address: updatedOrderForm});

}

render () {
    let error = null;
    if(this.state.error) {
        error = <div className="alert alert-warning" role="alert" >{this.state.errorMessage}. </div>
        setTimeout(() => {
          this.setState({errorStatus : false});
        }, 2000);
     }


    const formElementsArray = [];
    for (let key in this.state.address) {
        formElementsArray.push({
            id: key,
            config: this.state.address[key]
        });
    }
    let form = (
        <form onSubmit={this.orderHandler}>
            {formElementsArray.map(formElement => (
                <Input 
                    key={formElement.id}
                    elementType={formElement.config.elementType}
                    elementConfig={formElement.config.elementConfig}
                    value={formElement.config.value}
                    changed={(event) => this.inputChangedHandler(event, formElement.id)} />
            ))}
               <Button btnType="Success">Αποθήκευση</Button>
        </form>
    );
    if ( this.state.loading ) {
        form = <Spinner />;
    }
    return (
            <section id="rest">
                <div className="container">
                    <div className="col-lg-6 offset-lg-3 col-md-6 offset-md-3 col-s-12 col-xs-12 signup">
                        <h4>Στοιχεία καταστήματος</h4>
                        {error}
                        {form}
                    </div>
                </div>
            </section>
    );
}
}

  export default Shop;