import React, { Component } from 'react';

import Header from './Containers/Header/Header';
import Layout from './Containers/Layout/Layout';
import Footer from './Containers/Footer/Footer';

import './App.css';

class App extends Component {
  render() {
    return (

        <div>
          <Header {...this.props} />
          <Layout  />
          <Footer />
        </div>
    );
  }
}

export default App;
