import * as actionTypes from '../actions/actionsTypes';

const initialState = {
    address : '',
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.IN_CHANGE:
            return {
                ...state,
                address : action.value
            }
        default :
            return state;
    }
}

export default reducer;