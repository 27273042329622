import React , { Fragment } from 'react'
import Logo from '../Nav/NavItems/Logo/Logo';
import MainMenu from '../Nav/NavItems/MainMenu/MainMenu';
import DashboardMenu from '../Nav/NavItems/MainMenu/DashboardMenu';
import { Cookies } from 'react-cookie';

import './Nav.css';


//δημιουργία cookie
const cookies = new Cookies();

const nav = (props) => {
    // console.log(props);
    let menu = (
        <Fragment>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                <MainMenu  {...props}/>
            </div>
        </Fragment>
    );
    
    if(cookies.get('token')) { 
        menu = (
            <Fragment>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                <DashboardMenu  {...props}/>
            </div>
            </Fragment>
        );}
    return (
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <Logo />
                { menu }
            </nav>
    );
}

export default nav;