import React , { Component } from 'react';
import axios from '../../../axios';

class Email extends Component {

    state = {
          error : false,
    errorMessage : null,
    }

    componentDidMount () {
        var url_string = window.location.href;
        var url = new URL(url_string);
        var params = url.searchParams.get("token");
        axios.get( '/partner/confirmemail/' + params )
        .then( response => {
            sessionStorage.setItem('partnerId',  response.data.data._id)
            this.props.history.push('/login');
            
        } )
        .catch( error => {
            this.props.history.push('/email-confirmation');
        } );

    }

    
    render () {
            let error = null;
   
            if(this.state.error) {
                error = <div className="alert alert-warning" role="alert" >{this.state.errorMessage}</div>
                setTimeout(() => {
                  this.setState({error : false});
                }, 2000);
             }
        return (
            <section id="rest">
                <div className="container">
                    <div className="col-lg-6 offset-lg-3 col-md-6 offset-md-3 col-s-12 col-xs-12 signup" >
                        <h4>Επιβεβαίωση</h4>
                        {error}
                        <p>Το Email σας επιβεβαιώθηκε. Περιμένετε μέχρι να ανανεωθεί η σελίδα</p>
                    </div>
                </div>
            </section>
        )
    }
}

export default Email;