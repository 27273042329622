import * as actionTypes from './actionsTypes';
import axios from '../../axios';
import { modalBackdrop } from './index'
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authSuccess = (token , partnerId) => {
    // console.log("auth saccess", token, partnerId)
    return {
        type: actionTypes.AUTH_SUCCESS,
        token : token,
        partnerId : partnerId,
    };
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error : error
    };
};


export const logout = () => {
    cookies.remove('partnerId');
    cookies.remove('token');
    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const auth = (data, isSignup) => {
    return dispatch => {
        dispatch(authStart());
        let url = '/partner/signup'
        if (!isSignup) { url = '/partner/login'}

        axios.post( url, data )
        .then( response => {
            // console.log(response);
            cookies.set('partnerId', response.data.partnerId,  {maxAge:  24 * 60 * 60 * 1000});
            cookies.set('token', response.data.token, {maxAge:  24 * 60 * 60 * 1000}); 
            dispatch(authSuccess(response.data.token, response.data.partnerId)); 
            dispatch(modalBackdrop());           
        } )
        .catch( error => {
            // console.log(error.response);
            dispatch(authFail(error.response.data));
        } );
    };
};