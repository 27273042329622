export const IN_CHANGE= 'IN_CHANGE'; 

export const FETCH_SHOPS = 'FETCH_SHOPS';

export const LOADER = 'LOADER';
export const ERROR_HUNDLING = 'ERROR_HUNDLING';


//auth action types
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';


//ui action types 
export const MODAL_SHOW = 'MODAL_SHOW';
export const MODAL_BACKDROP = 'MODAL_BACKDROP';


//filters 
export const CATEGORY_FILTER = 'CATEGORY_FILTER';
export const BRAND_FILTER = 'BRAND_FILTER';
export const MODEL_FILTER = 'MODEL_FILTER';
export const ISSUE_FILTER = 'ISSUE_FILTER';
export const SEARCH_FILTER = 'SEARCH_FILTER';

