import React, { Component } from 'react';
import { Cookies } from 'react-cookie';
import { Route, Switch } from 'react-router-dom';
import './Dashboard.css';
import Profile from './Profile';
import Comments from './Comments';
import Enquiries from './Enquiries';
import Shops from './Shops';


//δημιουργία cookie
const cookies = new Cookies();


class Dashboard extends Component {

componentDidMount() {
  if (!cookies.get('token') || cookies.get('token') === 'undefined') {
    this.props.history.replace('/login')
}
}


render () {

  return(
            <div style={{minHeight : '80vh'}}>
                <div className="requests">
                  <Switch>
                    <Route path="/dashboard/profile"  component ={Profile} />
                    <Route path="/dashboard/comments"  component ={Comments} />
                    <Route path="/dashboard/shops"  component ={Shops} />
                    <Route path="/dashboard" component={Enquiries} />
                  </Switch>
                </div>
          </div> 
    );
  }
}

  export default Dashboard;