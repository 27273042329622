import React, { Component } from 'react';
import Input from '../../../Components/UI/input/input';
import Button from '../../../Components/UI/Button/Button';
import Spinner from '../../../Components/UI/Spinner/Spinner';

import axios from '../../../axios';


class Details extends Component {


state = {
    shop : {
        formal_name : {elementType : 'input',
        elementConfig : {
            type : 'text',
            placeholder : 'Επωνυμία επιχείρησης'
        },
        value : ''
    },
    vat: {elementType : 'input',
        elementConfig : {
            type : 'text',
            placeholder : 'Α.Φ.Μ',
            pattern : '.{9,9}' ,
            required : true,
            title : 'ΤΟ Α.Φ.Μ. πρέπει να έχει 9 αριθμους'
        },
        value : ''
    },
    tax_office: {elementType : 'input',
        elementConfig : {
            type : 'text',
            placeholder : 'Δ.Ο.Υ'
        },
        value : ''
    },

    },
    loading: false,
    error : false,
    errorMessage : null,
}

orderHandler = ( event ) => {
    event.preventDefault();
    this.setState( { loading: true } );
    const formData = {};
    for (let formElementIdentifier in this.state.shop) {
        formData[formElementIdentifier] = this.state.shop[formElementIdentifier].value;
    }
    const details = {
        formal_name : formData.formal_name,
        vat : formData.vat,
        tax_office : formData.tax_office,
        partner: sessionStorage.getItem('partnerId') 
        
    }
    axios.post( '/shop/formal', details )
        .then( response => {
            this.setState( { loading: false } );
            this.props.history.replace('/email-confirmation')
        } )
        .catch( error => {
            console.log(error);
            this.setState( { loading: false, error : true,
                errorMessage : error.response.data.message});
        } );
}


inputChangedHandler = (event, inputIdentifier) => {
    const updatedOrderForm = {
        ...this.state.shop
    };
    const updatedFormElement = { 
        ...updatedOrderForm[inputIdentifier]
    };
    updatedFormElement.value = event.target.value;
    updatedOrderForm[inputIdentifier] = updatedFormElement;
    this.setState({shop: updatedOrderForm});

}

skipHandler =() => {
    this.props.history.replace('/email-confirmation')
}

render () {
    let error = null;
    if(this.state.error) {
        error = <div className="alert alert-warning" role="alert" >{this.state.errorMessage}</div>
        setTimeout(() => {
          this.setState({error : false});
        }, 2000);
     }


    const formElementsArray = [];
    for (let key in this.state.shop) {
        formElementsArray.push({
            id: key,
            config: this.state.shop[key]
        });
    }
    let form = (
        <form onSubmit={this.orderHandler}>
            {formElementsArray.map(formElement => (
                <Input 
                    key={formElement.id}
                    elementType={formElement.config.elementType}
                    elementConfig={formElement.config.elementConfig}
                    value={formElement.config.value}
                    changed={(event) => this.inputChangedHandler(event, formElement.id)} />
            ))}
               <Button btnType="Success">Αποθήκευση</Button>

        </form>
    );
    if ( this.state.loading ) {
        form = <Spinner />;
    }
    return (
            <section id="rest">
                <div className="container">
                    <div className="col-lg-6 offset-lg-3 col-md-6 offset-md-3 col-s-12 col-xs-12 signup">
                        <h4>Επίσημα στοιχεία επιχείρησης</h4>
                        {error}
                        {form}
                        <p style={{marginTop : '20px', cursor :'pointer' }}  onClick={this.skipHandler}>Παράλειψη αυτό του βήματος..</p>
                    </div>
                </div>
            </section>
    );
}
}

  export default Details;