import React from 'react'
import './MenuItems.css';

const menuitems = (props) => {
    return (
        <li className="nav-item">
            <a className="nav-link"  href={props.href} onClick={props.clicked}>{props.name}</a>
        </li>
    );
}

export default menuitems;