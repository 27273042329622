import React  from 'react';
import Nav from '../Nav/Nav';


const confirmation = ( props ) => (
    <div>
        <Nav link='login' name='Σύνδεση' />
        <div className="container" style={{minHeight : '80vh', textAlign: "center"}}>
            <h3 style={{marginTop : "100px"}}>Ευχαριστούμε για την καταχώρηση</h3>
            <h4 style={{marginTop :"60px"}}>Σύντομα θα επικοινωνήσουμε μαζί σου και θα μπορείς ξεκινήσεις να δέχεσαι αιτήματα επισκευής μέσα από το <strong>FindFix</strong></h4>
        </div>
    </div>
);

export default confirmation;