import * as actionTypes from './actionsTypes';

export const modalShow = (value) => {
    return {
        type: actionTypes.MODAL_SHOW,
        value : value,
    };
};

export const modalShowUser = (value,  user) => {
    // console.log(user);
    return (dispatch) =>{ 
        if (user) {dispatch(modalShow(value)) 
        } else { dispatch(modalShow('ΣΥΝΔΕΣΗ', null))}     
    }

}

export const modalBackdrop = () => {
    return {
        type: actionTypes.MODAL_BACKDROP,
    };
};



