import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import styles from './Footer.module.css';

  class Footer extends Component {
   

    render() {
      return (
        <div className={styles.footer}>
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">
                <h5>Εταιρια</h5>
                <a href="/"><li>Σχετικά</li></a>
                <Link to="/contact"><li>Επικοινωνία</li></Link>
                <a href="https://blog.findfix.gr/"><li>Blog</li></a>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">
                <h5>Ιστοσελιδα</h5>
                <Link to="/terms"><li>Όροι χρήσης</li></Link>
                <Link to="/terms"><li>Πολιτική απορρήτου</li></Link>
                <a href="/"><li>Χαρτης Ιστότοπου</li></a>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">
                <h5>Υποστηριξη</h5>
                <Link to='/pricing'><li>Τιμοκατάλογος</li></Link>
                <Link to='/operation'><li>Πως λειτουργεί</li></Link>
              </div>
              <div className={`col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 ${styles.socialIcons}`}>
              <h5>Ακολούθησέ μας :</h5>
              <a href="https://www.facebook.com/FindFix.gr"><ion-icon ios="logo-facebook" md="logo-facebook"></ion-icon></a>
              <a href="https://www.twitter.com/FindFix.gr"><ion-icon ios="logo-twitter" md="logo-twitter"></ion-icon></a>
              <a href="https://www.instagram.com/FindFix.gr"><ion-icon ios="logo-instagram" md="logo-instagram"></ion-icon></a>
              </div>
            </div>
        <div className="row">
          <div className={`col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 ${styles.footerLogo}`}>
            <img src="../img/logo-white.svg" alt="FindFix" width="100px" />
          </div>
          </div>
        </div>
          <p style={{textAlign : 'center'}}>2019 © made with ♥ by FindFix Team</p>
        </div>
        
        )
    }
  }
export default Footer;
