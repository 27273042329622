import * as actionTypes from '../actions/actionsTypes';

const initialState = {
    token : null,
    partnerId : null,
    error : null,
    loading : null,
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.AUTH_START:
            return {
                ...state,
                error : null,
                loading : true
                     }
        case actionTypes.AUTH_SUCCESS:
        return {
            ...state,
            error : null,
            loading : false,
            token : action.token,
            partnerId : action.partnerId
                }
        case actionTypes.AUTH_LOGOUT:
        return {
            ...state,
            error : null,
            loading : false,
            token : null,
            partnerId :null
                }
        case actionTypes.AUTH_FAIL:
        return {
            ...state,
            error : action.error,
            loading : false
                    }
        default :
            return state;
    }
}

export default reducer;