import React, { Component } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/lib/animated';


let categoryOptions = [
    { value: 'Σταθερός Η/Υ', label: 'Σταθερούς Η/Υ', name: 'category', isFixed: true },
    { value: 'Laptop', label: 'Laptop', name: 'category'},
    { value: 'Κινητό', label: 'Κινητά', name: 'category'},
    { value: 'Tablet', label: 'Tablet', name: 'category', isFixed: true },
    { value: 'Εκτυπωτής', label: 'Εκτυπωτές', name: 'category' },
    { value: 'Game Console', label: 'Παιχνίδομηχανές', name: 'category' },
  ];



class Category extends Component {
    state = {
    } 

    componentWillReceiveProps({category}) {
        let defaultValueArray = [];
        let y;
            for (var x=0;x < categoryOptions.length; x ++) {
                for (y in category) {
                    if (categoryOptions[x].value === category[y]) {
                        defaultValueArray.push(categoryOptions[x]);                
                    }
                }
            }
            // console.log(defaultValueArray);
        this.setState({...this.state,defaultValueArray})
      }

    render() {
        let selectEllement = null;
        if(this.state.defaultValueArray) {
            // console.log(this.state.defaultValueArray)
            selectEllement = (
                <Select
                closeMenuOnSelect={false}
                components={makeAnimated()}
                value={this.state.defaultValueArray}
                isMulti
                options={categoryOptions}
                onChange={(opt, target) => this.props.change(opt, target)} 
                />
            )
        }
   
    return (
        <div className="container">
        <h3>Τι επισκευάζεις;</h3>
        <p>μπορείς να επιλέξεις περισσότερα απο ένα</p>
            {selectEllement}
        <button type="submit" className="btn btn-info" style={{marginTop: '20px'}} name="category" onClick={this.props.submit}>Αποθήκευση</button>
        </div>
    );
        }
}

export default Category;
