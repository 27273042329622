import React from 'react';
import styles from './Socket.module.css';

const socket = (props) =>
{
    return (    
        <div className={styles.socket}>
        <h5>{props.enquiry}</h5>
        <div className="row" >
          <div className="col-s-12 col-md-4 col-lg-4 col-xl-4">
            <p><strong>Πελάτης</strong></p>
            <p>{props.user.first_name} {props.user.last_name}</p>
            <p><b>Περιοχή : </b>{props.address.locality}</p>
          </div>
          <div className="col-s-12 col-md-4 col-lg-4 col-xl-4">
            <p>Είδος : {props.category} {props.brand} {props.model}</p>
            <p><strong>Πρόβλημα : {props.issue}</strong></p>
            <p>{props.comment}</p>
          </div>
          <div className="col-s-12 col-md-4 col-lg-4 col-xl-4">
            <p><button onClick={props.clicked} className="btn btn-success">Αποδοχή</button></p>
            <p><button onClick={props.denied} className="btn btn-danger">Απόρριψη</button></p>
          </div>
        </div>
        </div>
    );
}

export default socket;