import React, { Component } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/lib/animated';


   const servicesOptions = [
        { value: 'Παραλαβή απο τον χώρο του πελάτη', label: 'Παραλαβή απο τον χώρο του πελάτη', name: 'services'},
        { value: 'Επισκευή στο χώρο του πελάτη', label: 'Επισκευή στο χώρο του πελάτη', name: 'services'},
        { value: 'Επίσκεψη στο κατάστημα', label: 'Επίσκεψη στο κατάστημα', name: 'services'},
      ];

class Services extends Component {
    state = {
    } 

    componentWillReceiveProps({services}) {
        let defaultValueArray = [];
        let y;
            for (var x=0;x < servicesOptions.length; x ++) {
                for (y in services) {
                    if (servicesOptions[x].value === services[y]) {
                        defaultValueArray.push(servicesOptions[x]);                
                    }
                }
            }
            // console.log(defaultValueArray);
        this.setState({...this.state,defaultValueArray})
      }

 render() {
    //  console.log(this.state);
    let selectEllement = null;
    if(this.state.defaultValueArray) {
        selectEllement = (
            <Select
            closeMenuOnSelect={false}
            components={makeAnimated()}
            value={this.state.defaultValueArray}
            isMulti
            options={servicesOptions}
            onChange={(opt, target) => this.props.change(opt, target)} 
            />
        )
    } 
    return (
        <div className="container">
        <h3>Τρόποι παραλαβής-αποστολής;</h3>
        <p>μπορείς να επιλέξεις περισσότερα απο ένα</p>
        { selectEllement }
        <button type="submit" name="services" className="btn btn-info" style={{marginTop: '20px'}} onClick={this.props.submit}>Αποθήκευση</button>
        </div>
    );
    }
}

export default Services;
