import React from 'react';

const textarea = (props) => {
    return (
    <div className="container">
        <h3>Λίγα λόγια για την επιχείρηση</h3>
        <div className="form-group">
            <textarea className="form-control" id="Textarea" placeholder="Ειδίκευση, Προσφορές, Ιστορικό κ.α." value={props.details} onChange={props.changeText} rows="5"></textarea>
        </div>
        <button type="submit"  name="details" className="btn btn-info" style={{marginTop: '20px'}} onClick={props.submit}>Αποθήκευση</button>
    </div>
    );

}

export default textarea;