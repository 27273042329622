import React from 'react';

const closedRequests = (props) => {
    return(  
      <tr>
        <th scope="row">{props.number}</th>
          <td>
            <p>{props.date.substring(8,10)}/{props.date.substring(5,7)}/{props.date.substring(0,4)}</p>
            <p>Eπισκευή {props.category} {props.brand} {props.model}</p>
            <p>{props.issue}</p>
            <p>{props.comment}</p>
          </td>
          <td>
            <p>{props.first_name} {props.last_name}</p>
            <p>{props.route} {props.street_number} {props.locality} {props.postal_code}</p>
            <p><strong>Τηλέφωνο : </strong>{props.phone}</p>
          </td>
          <td>
            <p>Ολοκληρωμένη επισκευή</p>
            <p>Ημερομηνία : {props.date_closed.substring(8,10)}/{props.date_closed.substring(5,7)}/{props.date_closed.substring(0,4)}</p>  
          </td>
      </tr>
         
    );
}

export default closedRequests;