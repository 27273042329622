import React from 'react'

import styles from './MainMenu.module.css';
import MenuItems  from '../MenuItems/MenuItems';

const mainmenu = (props) => {
    return (
            <ul className="navbar-nav"> 
                <div className={styles.mainmenuLink}>
                    <MenuItems name='ΣΥΝΔΕΣΗ' clicked={() => props.modalShow('ΣΥΝΔΕΣΗ')} />
                </div>
                {/* <MenuItems name='ΕΓΓΡΑΦΗ' clicked={() => props.modalShow('ΕΓΓΡΑΦΗ')} /> */}
            </ul>
    );
}

export default mainmenu;