import React from 'react'
import MenuItems  from '../MenuItems/MenuItems';

const mainmenu = (props) => {
    return (
            <ul className="navbar-nav"> 
                <MenuItems name='ΑΙΤΗΜΑΤΑ' href='/dashboard' />
                <MenuItems name='ΠΡΟΦΙΛ' href='/dashboard/profile'/>
                <MenuItems name='ΚΡΙΤΙΚΕΣ' href='/dashboard/comments'/>
                <MenuItems name='ΚΑΤΑΣΤΗΜΑΤΑ' href='/dashboard/shops'/>
                <MenuItems name='ΑΠΟΣΥΝΔΕΣΗ' href='/logout'/>
            </ul>
    );
}

export default mainmenu;